//styles
import './bannerSlider.scss'
import BannerSliderImg from '../../../../assets/images/banner-slider.png'

//react
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const BannerSlider = () => {
    const { t } = useTranslation('home', {keyPrefix: 'banner.bannerSlider',})

    const items = [
        {
            title: 'Sheriff Base1',
            content: t('itemText1')
        },
        {
            title: 'Sheriff Base2',
            content: t('itemText2')
        },
        {
            title: 'Sheriff Base3',
            content: t('itemText3')
        },
        {
            title: 'Sheriff Base4',
            content: t('itemText4')
        }
    ]

    const [page, setPage] = useState(1)

    const [itemWidth, setItemWidth] = useState(0)
    const itemRef = useRef<HTMLDivElement>(null)
    const contaienrRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const interval = setInterval(() => {
            console.log(page, items.length-1)
            if(page < items.length){
                setPage(page+1)
            }else{
                setPage(1)
            }
        }, 3000)

        return () => {
            clearInterval(interval)
        }
    }, [page])


    useEffect(() => {
        if (itemRef.current && contaienrRef.current) {
            const gap = Number(window.getComputedStyle(contaienrRef.current).gap.replace('px', ''))
            const containerWidth = items.length * itemRef.current.clientWidth + items.length*gap - gap
            setItemWidth(containerWidth/items.length)

        }
    }, [itemRef, contaienrRef])

    return (
        <div className="banner-slider-container container">
            <div className="banner-slider-item-container" ref={contaienrRef}>
                {
                    items.map((item) => {
                        return (
                            <div className="banner-slider-item" ref={itemRef} style={{
                                transform: `translateX(-${itemWidth*(page-1)}px)`
                            }}>
                                <p className="banner-slider-item-title">{item.title}</p>
                                <div className="banner-slider-item-container">
                                    <div className="banner-slider-item-img">
                                        <img src={BannerSliderImg} />
                                    </div>
                                    <div className="banner-slider-item-content">
                                        <p>{item.content}</p>
                                        <div className="banner-slider-content-arrow"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="banner-slider-page-container">
                {
                    (new Array(items.length).fill(0)).map((item, index) => {
                        return (
                            <div className={`banner-slider-page-item ${index + 1 === page ? 'active' : ''}`} onClick={() => {
                                setPage(index+1)
                            }}></div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BannerSlider