//styles
import './layout.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import Header from './header/header'
import Footer from './footer/footer'

type layoutProps = {
    children: JSX.Element | JSX.Element[]
}

const Layout: FC<layoutProps> = ({ children }) => {

    const [top, setTop] = useState(0)

    useEffect(() => {
        const header = document.querySelector('#header')

        setTop(header?.clientHeight || 0)
    }, [])

    return (
        <div className="layout">
            <Header/>
            <div className="layout-content" style={{
                top: `${top}px`
            }}>
                {children}
                <Footer/>
            </div>
        </div>
    )
}

export default Layout