//styles
import './adminPanel.scss'

//react
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//components
import Layout from '../../components/layout/layout'
import Input from '../../components/general/input/input'
import AdminPaginationButton from '../../components/adminPanel/adminPaginationButton/adminPaginationButton'
import Checkbox from '../../components/general/checkbox/checkbox'

//network
import { authorizedRequest } from '../../network/requests'
import { contactUrl } from '../../network/urls'

export enum statusTypes {
    new='New',
    inProgress='In Progress',
    closed='Closed'
}

export type application = {
    id: number
    name: string
    message: string
    placedDate: Date
    lastUpdateDate: Date
    phoneNumber: string
    status: statusTypes
}

type applicationResponse = {
    id: number
    name: string
    message: string
    date: number
    last_update_date: number
    phone_number: string
    status: statusTypes
}
const arrow = <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" enableBackground={"new 0 0 128 128"} xmlSpace="preserve"><style></style><g id="row1_1_"><g id="_x31__3_"><path className="st2" d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/></g></g></svg>

const AdminPanel = () => {
    const { t } = useTranslation('general', {keyPrefix: 'adminPanel',})
    const [applicationList, setApplicationList] = useState<application[]>([])

    const [page, setPage] = useState(1)
    const [request, setRequest] = useState('')
    const [totalPages, setTotalPages] = useState(1)
    const [closed, setClosed] = useState(false)

    const createNumberRange = (number: number) => {
        const start = Math.max(1, Math.min(number - 3, totalPages - 6)); // Ensure the range starts within valid bounds
        const end = Math.min(totalPages, start + 6); // Ensure the range ends within totalPages
        const range = [];

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    }
    

    useEffect(() => {
        authorizedRequest(contactUrl + `?page=${page}&request=${request}&per_page=${10}&closed=${closed ? 1 : 0}`, 'GET')
        .then((response) => {
            if(response && response.result){
                setApplicationList(response.result.contacts.map((item: applicationResponse) => {
                    return {
                        id: item.id,
                        name: item.name,
                        message: item.message,
                        placedDate: new Date(item.date * 1000),
                        lastUpdateDate: new Date(item.last_update_date * 1000),
                        status: item.status,
                        phoneNumber: item.phone_number
                    }
                }))
                setTotalPages(Math.ceil(response.result.total_contacts/6))
            }
        })
    }, [request, page, closed])

    return (
        <Layout>
            <div className="admin-panel-wrapper">
                <div className="admin-panel-container">
                    <div className="admin-panel-title">{t('applications')}</div>
                    <Input 
                        type={'text'}
                        value={request}
                        placeholder={t('search')}
                        onChange={(e) => {
                            setRequest(e.target.value)
                        }}
                    />
                    <Checkbox
                      checked={closed}
                      onChange={() => {
                        setClosed(!closed)
                      }}
                      label={t('closed')}
                    />
                    <div className="admin-panel-application-list">
                        {applicationList.map((application, index) => (
                            <div key={`admin-panel-application-container-${index}`} className="admin-panel-application-container">
                                <div className="admin-panel-application-text">{t('name')} {application.name}</div>
                                <div className="admin-panel-application-text">{t('phoneNumber')} +{application.phoneNumber}</div>
                                <div className="admin-panel-application-text">{t('message')} {application.message}</div>
                                <div className="admin-panel-application-button" onClick={() => {
                                    console.log(true)
                                    authorizedRequest(contactUrl, 'PUT', {
                                        contact_id: application.id
                                    })
                                    .then((response) => {
                                        setApplicationList([...applicationList.filter((contact) => {
                                            return contact.id !== application.id
                                        })])
                                    })
                                }}>{t('closeAppliation')}</div>
                            </div>
                        ))}
                    </div>
                    <div className="admin-pagination-container">
                        <AdminPaginationButton onClick={() => {
                            setPage(page-1 > 0 ? page-1 : 1)
                        }} >
                            {arrow}
                        </AdminPaginationButton>

                        {
                            createNumberRange(page).map((elem) => {
                                return (
                                    <AdminPaginationButton active={page === elem} onClick={() => {
                                        setPage(elem)
                                    }} >
                                        <p>{elem}</p>
                                    </AdminPaginationButton>
                                )
                            })
                        }

                        <AdminPaginationButton onClick={() => {
                            setPage(page+1 > totalPages ? totalPages : page+1)
                        }} >
                            {arrow}
                        </AdminPaginationButton>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AdminPanel