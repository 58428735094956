//styles
import './home.scss'

//components
import Layout from '../../components/layout/layout'
import Banner from '../../components/home/banner/banner'
import FormSection from '../../components/home/formSection/formSection'
import Reasons from '../../components/home/reasons/reasons'
import Services from '../../components/home/services/services'

const Home = () => {
    return (
        <Layout>
            <Banner/>
            <Reasons/>
            <Services/>
            <FormSection/>
        </Layout>
    )
}

export default Home