//styles
import "./checkbox.scss"

//react
import { FC } from "react"

type checkboxProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  className?: string;
};

const Checkbox: FC<checkboxProps> = ({ checked, onChange, label, className }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <label className={`checkbox-container ${className || ""}`} onClick={handleChange}>
      <div className={`checkbox ${checked ? "checked" : ""}`}>
        {checked && <span className="checkmark"></span>}
      </div>
      {label && <span className="checkbox-label">{label}</span>}
    </label>
  );
};

export default Checkbox;
