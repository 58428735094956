//styles
import './button.scss'

//react
import { FC } from 'react'

type buttonProps = {
    text: string
    inverse?: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> void
}

const Button: FC<buttonProps> = ({ text, inverse, onClick }) => {
    return (
        <div className={`button ${inverse && 'inverse'}`} onClick={onClick}>
            <p>{text}</p>
        </div>
    )
}

export default Button