//styles
import './paymentItem.scss'

//react
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

//components
import Button from '../../general/button/button'

type paymentItemType = {
    title: string
    text: string
    image: string
    button?: boolean
}

const PaymentItem: FC<paymentItemType> = ({ title, text, image, button}) => {
    const { t } = useTranslation('general', {keyPrefix: 'payment',})
    return (
        <div className="payment-item-container">
            <img src={image} alt="" />
            <div className="payment-item-text-container">
                <div className="payment-item-title">{title}</div>
                <div className="payment-item-text">{text}</div>
                {
                    button ? <Button text={t("detailesButton")} inverse={false}/> : null
                }
                
            </div>
        </div>   
    )
}

export default PaymentItem