//styles
import './banner.scss'
import BannerHouse from '../../../assets/images/banner-house.png'

//components
import Button from '../../general/button/button'
import BannerSlider from "./bannerSlider/bannerSlider"

//react
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Banner = () => {
    const { t } = useTranslation('home', {keyPrefix: 'banner',})

    const navigate = useNavigate()

    return (
        <div className="home-banner">
            <div className="container">
                <div className="home-banner-container">
                    <div className="home-banner-content-container">
                        <div className="home-banner-content">
                            <div className="home-banner-content-text">
                                <h2 className="home-banner-title">SHERIFF</h2>
                                <h4 className="home-banner-subtitle">{t('bannerSubtitle')}</h4>
                            </div>
                            <Button text={t('bannerButton')} onClick={()=> navigate('/services')}/>
                        </div>
                        <div className="home-banner-background">
                            <img src={BannerHouse} alt="" />
                        </div>
                    </div>
                    <BannerSlider/>
                </div>
            </div>
        </div>
    )

}

export default Banner