//styles
import './languageSelector.scss'

//react
import { useState } from 'react'

//translation
import { useTranslation } from 'react-i18next'

enum languages {
    EN = 'en',
    UA = 'ua'
}

const LanguageSelector = () => {
    const [t, i18n] = useTranslation('global')
    const [ currentLang, setCurrentLang ] = useState<languages>(languages.EN)


    const handleChangeLanguage = (lang: languages) => {
        i18n.changeLanguage(lang)
    }

    return (
        <div className="language-selector">
            <p className={`${currentLang === languages.UA ? 'active' : ''}`} onClick={() => {
                setCurrentLang(languages.UA)
                handleChangeLanguage(languages.UA)
            }}>ua</p>
            <p className={`${currentLang === languages.EN ? 'active' : ''}`} onClick={() => {
                setCurrentLang(languages.EN)
                handleChangeLanguage(languages.EN)
            }}>en</p>
        </div>
    )
}

export default LanguageSelector