//styles
import './App.scss'

import Router from './router';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling effect
    });
  }, [pathname]); // Triggers whenever the route path changes

  return (
    <>
      <Router/>
    </>
  )
}

export default App
