//styles
import './contacts.scss'

//react
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

//components
import Button from '../../components/general/button/button'
import Layout from '../../components/layout/layout'
import ContactItem from '../../components/contacts/contactItem/contactItem'
import FeedbackModal from '../../components/general/feedbackModal/feedbackModal'

const Contacts = () => {
    const { t } = useTranslation('general', {keyPrefix: 'contacts',})
    const [applicationModal, setApplicationModal] = useState(false)
    
    
    return (
        <Layout>
            <div className="contacts-wrapper">
                <div className="contacts-title">
                    {t("title")}
                </div>
                <div className="contacts-container">
                    <ContactItem city={t("itemCity")} street={t("itemStreet")} text={t("itemText")} phone={t("itemPhone")} email={t("itemEmail")}/>
                    <ContactItem city={t("itemCity")} street={t("itemStreet")} text={t("itemText")} phone={t("itemPhone")} email={t("itemEmail")}/>
                    <ContactItem city={t("itemCity")} street={t("itemStreet")} text={t("itemText")} phone={t("itemPhone")} email={t("itemEmail")}/>
                    <ContactItem city={t("itemCity")} street={t("itemStreet")} text={t("itemText")} phone={t("itemPhone")} email={t("itemEmail")}/>
                    <ContactItem city={t("itemCity")} street={t("itemStreet")} text={t("itemText")} phone={t("itemPhone")} email={t("itemEmail")}/>

                </div>
                <div className="image-container">
                    <img src={require('../../assets/images/contacts.jpg')} alt="" />
                    <div className="image-text-container">
                        <div className="image-text">
                            {t("textQuestion1")} <br/>  {t("textQuestion2")}
                        </div>
                        <Button text= {t('contactUs')} inverse={false} onClick={() => setApplicationModal(!applicationModal)}/>
                    </div>
                </div>
                <FeedbackModal
                    opened={applicationModal}
                    setOpened={() => setApplicationModal(!applicationModal)}
                />
            </div>
        </Layout>
    )
}

export default Contacts