//styles
import './login.scss'

//react
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import Input from '../../components/general/input/input'
import Button from '../../components/general/button/button'

//components
import Layout from '../../components/layout/layout'

//network
import { unauthorizedRequest } from '../../network/requests'
import { loginUrl } from '../../network/urls'

type errors = {
    email: string
    password: string
}

const Login = () => {
    const { t } = useTranslation('general', {keyPrefix: 'login'})
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState<errors>({email: '', password: ''})

    const navigate = useNavigate()

    const loginSubmit = () => {
        setErrors({email: '', password: ''})

        unauthorizedRequest(loginUrl, 'POST', {
            email: email,
            password: password
        })
        .then((response) => {
            console.log(response)
            if(response === 403){
                setErrors({email: t('incorrectEmailOrPassword'), password: t('incorrectEmailOrPassword')})
            }else{
                localStorage.setItem('accessToken', response.result.access_token)
                navigate('/admin')
            }
        })
    }
    
    return (
        <Layout>
            <div className="login-wrapper">
                <div className="login-container">
                    <div className="login-title">{t('signIn')}</div>
                    <div className="login-subtitle">{t('email')}</div>
                    <Input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={false}
                        placeholder={t('enterTheEmail')}
                        error={errors.email}
                    />
                    <div className="login-subtitle">{t('password')}</div>
                    <Input 
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={false}
                        placeholder={t('enterThePassword')}
                        error={errors.password}
                    />
                    <Button text={t('send')} inverse={false} onClick={(e) => {
                        e.preventDefault()
                        loginSubmit()
                    }}/>
                </div>
            </div>
        </Layout>
    )
}

export default Login