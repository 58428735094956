import { Routes, Route } from "react-router-dom"
import Home from "./pages/home/home"
import Login from "./pages/login/login"
import Services from "./pages/services/services"
import AdminPanel from "./pages/adminPanel/adminPanel"
import Contacts from "./pages/contacts/contacts"
import Payment from "./pages/payment/payment"

const Router = () => {
    return ( 
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/services/:section?" element={<Services />} />
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/payment" element={<Payment />} />
        </Routes>
    )
}

export default Router