//styles
import './contactItem.scss'

//react
import { FC } from 'react'

type paymentItemType = {
    city: string
    street: string
    text: string
    phone: string
    email: string
}

const ContactItem: FC<paymentItemType> = ({ city, street, text, phone, email}) => {
    return (
        <div className="contact-item-container">
            <div className="contact-item-title">{city}</div>
            <div className="contact-item-text">{street}</div>
            <div className="contact-item-subtitle">{text}</div>
            <div className="contact-item-text">{phone}</div>
            <div className="contact-item-text">{email}</div>
        </div> 
    )
}

export default ContactItem