//styles
import './serviceItem.scss'

//react
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

//components
import Button from '../../general/button/button'

type serviceItemType = {
    image: string
    title: string
    text: string
    price: string
    priceInfo: string
    onClick: ()=> void
    index: number
}

const ServiceItem: FC<serviceItemType> = ({ image, title, text, price, priceInfo, onClick, index }) => {
    const { t } = useTranslation('services', {keyPrefix: '',})
    return (
        <div key={`services-offer-item-container-${index}`} className="services-offer-item-container">
            <img src={image} alt="" />
            <div className="services-offer-item-title">{title}</div>
            <div className="services-offer-item-text">{text}</div>
            <div className="services-offer-item-action-container">
                <div className="services-offer-item-price">{t("fromPriceText")} <br /> <span>{price}</span> <br /> {priceInfo}</div>
                <Button text={t('itemButton')} inverse={false} onClick={onClick}/>
            </div>
        </div>
    )
}

export default ServiceItem