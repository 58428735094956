//styles
import './reasons.scss'
import ReasonImg from '../../../assets/images/reason.png'

//react
import { useTranslation } from 'react-i18next'

const Reasons = () => {
    const { t } = useTranslation('home', {keyPrefix: 'reasonse',})
    return (
        <div className="reasons">
            <div className="container">
                <div className="reasons-container">
                    <h2 className="reasons-title">
                        <span>{t('titleBlack')}</span> {t('title')} <span>SHERIFF</span>
                    </h2>
                    <p className="reasons-content">
                       {t('subtitle')}
                    </p>
                    <div className="reasons-item-container">
                        <div className="reasons-item">
                            <img src={ReasonImg} alt="" />
                            <p className="reason-item-title">{t('itemTitle1')}</p>
                            <p className="reason-item-text">
                                {t('itemText1')}
                            </p>
                        </div>
                        <div className="reasons-item">
                            <img src={ReasonImg} alt="" />
                            <p className="reason-item-title">{t('itemTitle1')}</p>
                            <p className="reason-item-text">
                                {t('itemText1')}
                            </p>
                        </div>
                        <div className="reasons-item">
                            <img src={ReasonImg} alt="" />
                            <p className="reason-item-title">{t('itemTitle1')}</p>
                            <p className="reason-item-text">
                                {t('itemText1')}
                            </p>
                        </div>
                        <div className="reasons-item">
                            <img src={ReasonImg} alt="" />
                            <p className="reason-item-title">{t('itemTitle1')}</p>
                            <p className="reason-item-text">
                                {t('itemText1')}
                            </p>
                        </div>
                        <div className="reasons-item">
                            <img src={ReasonImg} alt="" />
                            <p className="reason-item-title">{t('itemTitle1')}</p>
                            <p className="reason-item-text">
                                {t('itemText1')}
                            </p>
                        </div>
                        
                    </div>
                    <p className="reasons-content">{t('contentText1')}<br/>{t('contentText2')}</p>
                </div>
            </div>
        </div>
    )
}

export default Reasons