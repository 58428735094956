//styles
import { useState } from 'react'
import Input from '../../general/input/input'
import './formSection.scss'

//react
import Textarea from '../../general/textarea/textarea'
import { useTranslation } from 'react-i18next'

type errors = {
    name: string
    phoneNumber: string
}

const FormSection = () => {
    const { t } = useTranslation('home', {keyPrefix: 'formSection',})
    
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState<errors>({name: '', phoneNumber: ''})

    const submitForm = () => {
        if(name.length < 1){
            setErrors(prevErrors => ({ ...prevErrors, name: t('enterTheName')}))
        }
        if(phoneNumber.length !== 14){
            setErrors(prevErrors => ({ ...prevErrors, phoneNumber: t('enterThePhoneNumber')}))
        }
        if(phoneNumber.length == 14 && name.length > 1){
            setErrors({name: '', phoneNumber: ''})

        }
    }


    return(
        <div className="form-section-wrapper">
            <div className="form-section-container container">
                <div className="form-section">
                    <div className="form-section-title">{t('title')}</div>
                    <div className="form-section-subtitle">{t('subtitle')}</div>
                    <Input
                        type='text'
                        value={name}
                        placeholder={t('enterTheName')}
                        onChange={(e)=>setName(e.target.value)}
                        error={errors.name}
                    />
                    <Input
                        type='phone'
                        value={phoneNumber}
                        placeholder={t('enterThePhoneNumber')}
                        onChange={(e)=>setPhoneNumber(e.target.value)}
                        error={errors.phoneNumber}
                    />
                    <Textarea
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        placeholder={t('enterTheMessage')}
                    />

                    <div className="form-section-button" onClick={()=>submitForm()}>
                        {t('send')}
                    </div>

                    <div className="form-section-text">{t('text')}</div>
                </div>
                <div className="form-section-info">
                    <div className="form-section-info-container">
                        <div className="form-section-info-title">{t('contentTitle1')}</div>
                        <div className="form-section-info-subtitle">{t('contentText1')}</div>
                    </div>
                    <div className="form-section-info-container">
                        <div className="form-section-info-title">45000</div>
                        <div className="form-section-info-subtitle">{t('contentText2')}</div>
                    </div>
                    <div className="form-section-info-container">
                        <div className="form-section-info-title">2500{t('title')}</div>
                        <div className="form-section-info-subtitle">{t('contentText3')}</div>
                    </div>
                    <div className="form-section-info-container">
                        <div className="form-section-info-title">24/7</div>
                        <div className="form-section-info-subtitle">{t('contentTitle4')}</div>
                        <div className="form-section-info-subtitle">{t('contentText4')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSection