export const logo = <svg viewBox="0 0 214 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M203.677 16.6312L210.037 25.3614L205.413 28.7365L199.053 20.0064L192.711 28.7365L188.088 25.3614L194.448 16.6312L184.124 13.3315L185.936 7.80676L196.165 11.1819V0.396484H201.94V11.1819L212.169 7.80676L214 13.3315L203.677 16.6312Z" fill="#121212"/>
<path d="M19.5523 16.6312L25.9125 25.3614L21.2886 28.7365L14.9285 20.0064L8.58718 28.7365L3.96331 25.3614L10.3046 16.6312L0 13.3315L1.8118 7.80676L12.0409 11.1819V0.396484H17.816V11.1819L28.0452 7.80676L29.8569 13.3315L19.5523 16.6312Z" fill="#121212"/>
<path d="M38.6141 20.2146C38.9727 23.8537 42.6718 24.6645 44.8988 24.6645C47.088 24.6645 50.9758 24.212 50.9758 20.2146C50.9758 12.3517 34.0657 19.9317 34.0657 8.14693C34.0657 3.16904 38.6896 -0.0741347 44.7667 0.00128795C50.3153 0.0767106 54.7316 2.92391 55.3921 7.12873L50.0322 7.82639C49.5792 4.82834 46.7106 4.26267 44.7667 4.26267C42.7472 4.26267 39.3313 4.50779 39.3313 8.14693C39.3313 14.8218 56.2414 7.61897 56.2414 20.2146C56.2414 23.948 53.5992 29.0013 44.8988 29.0013C36.6324 29.0013 33.6694 24.4571 33.3108 20.8934L38.6141 20.2146Z" fill="#121212"/>
<path d="M75.7183 0.359375H80.9838V28.7183H75.7183V16.8581H63.6396V28.7183H58.3552V0.359375H63.6396V12.2384H75.7183V0.359375Z" fill="#121212"/>
<path d="M103.725 5.01672H88.646V12.2384H101.668V16.8581H88.646V24.0609H103.725V28.7183H83.4182V0.359375H103.725V5.01672Z" fill="#121212"/>
<path d="M128.43 28.7183H122.674L117.937 19.9315C117.616 19.9693 117.239 19.9693 116.88 19.9693H111.124V28.7183H105.858V0.359375H116.88C123.769 0.359375 128.072 4.13051 128.072 10.1643C128.072 14.2183 126.128 17.254 122.769 18.8002L128.43 28.7183ZM111.105 4.90359V15.4439H116.861C120.353 15.4439 122.41 13.7092 122.41 10.1832C122.41 6.65716 120.334 4.92244 116.861 4.92244H111.105V4.90359Z" fill="#121212"/>
<path d="M135.791 0.359375H130.525V28.7183H135.791V0.359375Z" fill="#121212"/>
<path d="M158.551 5.01672H143.472V12.2384H156.475V16.8581H143.472V28.7183H138.225V0.359375H158.551V5.01672Z" fill="#121212"/>
<path d="M180.972 5.01672H165.874V12.2384H178.896V16.8581H165.874V28.7183H160.646V0.359375H180.972V5.01672Z" fill="#121212"/>
</svg>
