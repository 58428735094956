//styles
import './services.scss'
import Services1 from '../../../assets/images/services1.png'

//components
import Button from '../../general/button/button'

//react
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Services = () => {
    const { t } = useTranslation('home', {keyPrefix: 'services',})
    
    const services = [
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
        {
            img: Services1,
            title: t('itemTitle1'),
            text: t('itemText1')
        },
    ]
    const sliderRef = useRef<HTMLDivElement>(null)
    const sliderContainerRef = useRef<HTMLDivElement>(null)

    const [page, setPage] = useState(1)

    const [itemWidth, setItemWidth] = useState(0)
    const [servicesPerPage, setServicesPerPage] = useState(window.screen.width > 768 ? 4 : window.screen.width > 425 ? 3 : 1)

    const navigate = useNavigate()


    useEffect(() => {
        if (sliderRef.current && sliderContainerRef.current) {
            const gap = Number(window.getComputedStyle(sliderContainerRef.current).gap.replace('px', ''))
            const containerWidth = services.length * sliderRef.current.clientWidth + services.length*gap - gap
            setItemWidth(containerWidth/services.length)

        }
    }, [sliderRef, sliderContainerRef])


    useEffect(() => {
        const resize = () => {
            setServicesPerPage(window.screen.width > 768 ? 4 : window.screen.width > 425 ? 3 : 1)
        }

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <div className="services">
            <div className="services-container container">
                <h2 className="services-title">{t('services')}</h2>
                <div className="services-slider-container">
                    <div className="services-slider-button" onClick={() => {
                        if(page - 1 > 0) {
                            setPage(page - 1)
                        }
                    }}>
                        <div className="services-slider-button-arrow"></div>
                    </div>
                    <div className="services-slider-content" ref={sliderContainerRef}>
                        {services.map((service) => {
                            return (
                                <div className="services-slider-item" ref={sliderRef} style={{
                                    left: `-${sliderRef.current ? (sliderRef.current.clientWidth+25) * (page-1): 0}px`
                                }}>
                                    <img className="services-slider-item-img" src={service.img} />
                                    <h3 className="services-slider-item-title">{service.title}</h3>
                                    <p className="services-slider-item-text">{service.text}</p>
                                    <Button text={t('itemButton')} inverse  onClick={()=>navigate('/services/popular-services')}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="services-slider-button" onClick={() => {
                        if(page < services.length-servicesPerPage+1){
                            setPage(page + 1)
                        }
                    }}>
                        <div className="services-slider-button-arrow"></div>
                    </div>
                </div>
                <div className="services-button">
                    <Button text={t('servicesButton')} onClick={()=> navigate('/services')}/>
                </div>
            </div>
        </div>
    )
}

export default Services