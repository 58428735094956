//styles
import './payment.scss'
import { logo } from '../../assets/icons'

//react
import { useTranslation } from 'react-i18next'

//components
import PaymentItem from '../../components/payment/paymentItem/paymentItem'
import Layout from '../../components/layout/layout'



const Payment = () => {
    const { t } = useTranslation('general', {keyPrefix: 'payment',})
    
    return (
        <Layout>
            <div className="payment-wrapper">
                <div className="payment-title">
                    {t("title1")}
                </div>
                <div className="basic-info-container">
                    <div className="basic-info-text-container">
                        <div className="basic-info-title">{t("basicInfoTitle")}</div>
                        <div className="basic-info-text">{t("basicInfoText1")}</div>
                        <div className="basic-info-text">{t("basicInfoText2")}</div>
                        <div className="basic-info-text">{t("basicInfoText3")}</div>
                    </div>
                    {logo}
                </div>
                <div className="payment-title">
                    {t("title2")}
                </div>
                <div className="payment-item-list">
                    <PaymentItem title={t("itemTitle1")} text={t("itemText1")} image={require('../../assets/images/download.png')} button={true}/>
                    <PaymentItem title={t("itemTitle1")} text={t("itemText1")} image={require('../../assets/images/download.png')} button={true}/>
                    <PaymentItem title={t("itemTitle1")} text={t("itemText1")} image={require('../../assets/images/download.png')} button={true}/>
                </div>
            </div>
        </Layout>
    )
}

export default Payment