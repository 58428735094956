//styles
import './burger.scss'

//components
import LanguageSelector from '../languageSelector/languageSelector'

//react
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Burger = () => {
    const { t } = useTranslation('general', {keyPrefix: 'header'})
    const [ active, setActive ] = useState(false)

    const [topOffset, setTopOffset] = useState(0)
    
    useEffect(() => {
        const closeBurger = () => {
            setActive(false)
        }

        window.addEventListener('click', closeBurger)

        return () => {
            window.removeEventListener('click', closeBurger)
        }
    }, [])

    useEffect(() => {
        const header = document.querySelector('.header')

        if(header){
            setTopOffset(header.clientHeight - 2)
        }
    }, [])

    return (
        <div className="burger" onClick={(e) => {
            e.stopPropagation()
        }}>
            <div className={`burger-header ${active ? 'active' : ''}`} onClick={() => {
                setActive(!active)
            }} >
                <div className="burger-header-item"></div>
                <div className="burger-header-item"></div>
                <div className="burger-header-item"></div>
            </div>
            <div className={`burger-container ${active ? 'active' : ''}`} style={{
                top: `${topOffset}px`
            }}>
                <div className="burger-container-links">
                    <Link to={'/services'}>{t("services")}</Link>                        
                    <Link to={'/payment'}>{t("payment")}</Link>
                    <Link to={'/contacts'}>{t("contactUs")}</Link>
                </div>
                <LanguageSelector/>
                
            </div>
        </div>
    )
}

export default Burger