//styles
import './feedbackModal.scss'

//react
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

//components
import Modal from '../modal/modal'
import Input from '../input/input'
import Button from '../button/button'
import Textarea from '../textarea/textarea'

//network
import { unauthorizedRequest } from '../../../network/requests'
import { contactUrl } from '../../../network/urls'


type modalProps = {
    opened: boolean
    setOpened: (value: boolean) => void
}

type errors = {
    name?: string
    phone?: string
    city?: string
}

const FeedbackModal: FC<modalProps> = ({opened, setOpened}) => {
    const { t } = useTranslation('general', {keyPrefix: 'feedbackModal'})
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState<errors>({name: '', phone: ''})


    const checkErrors = () => {
        setErrors({name: '', phone: ''})
        let flag = true

		if (name.length < 2) {
			setErrors(prevErrors => ({...prevErrors, name: t('enterTheName')}))
			flag = false
		} else setErrors(prevErrors => ({...prevErrors, name: ''}))
        
        if (phone.length < 1) {
            setErrors(prevErrors => ({...prevErrors, phone: t('enterThePhoneNumber')}))
            flag = false
        } else setErrors(prevErrors => ({...prevErrors, phone: ''}))


        return flag
    }

    return(
        <Modal
            opened={opened}
            setOpened={()=>setOpened(!opened)}
        >
            <div className="feedback-modal-wrapper">
                <div className="feedback-modal-image-container">
                    <div className="feedback-modal-title">
                        Security21
                    </div>
                    <img src={require('../../../assets/images/feedbackModal.png')} alt="" />
                    <div className="feedback-modal-phone">
                        {t('title')}
                        <br/>
                        <span>+1 314 400 1284</span>  {t('free')}
                    </div>
                </div>
                <div className="feedback-modal-form-container">
                    <div className="feedback-modal-form-title">
                        {t('subtitle')}
                    </div>
                    <div className="feedback-modal-form-subtitle">
                        {t('text')}
                    </div>

                    <Input
                        type='text'
                        placeholder={t('enterTheName')}
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        error={errors.name}
                    />

                    <Input
                        type='phone'
                        placeholder={t('enterThePhoneNumber')}
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                        error={errors.phone}
                    />

                    <Textarea
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        placeholder={t('enterTheMessage')}
                    />
                    <Button text= {t('send')} inverse={false} onClick={() => {
                        if(checkErrors()){
                            unauthorizedRequest(contactUrl, 'POST', {
                                name: name,
                                message: message,
                                phone_number: phone
                            })
                            .then(() => {
                                setOpened(false)
                            })
                        }
                    }}/>
                </div>
            </div>
        </Modal>
    )
    
}

export default FeedbackModal