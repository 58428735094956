import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import home_en from './translations/en/home.json'
import home_ua from './translations/ua/home.json'
import general_en from './translations/en/general.json'
import general_ua from './translations/ua/general.json'
import services_en from './translations/en/services.json'
import services_ua from './translations/ua/services.json'

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'en',
  fallbackLng: "en",
  resources: {
    en: {
      home: home_en,
      general: general_en,
      services: services_en
    },
    ua: {
      home: home_ua,
      general: general_ua,
      services: services_ua
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
