//styles
import './services.scss'

//react
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

//components
import FeedbackModal from '../../components/general/feedbackModal/feedbackModal'
import Layout from '../../components/layout/layout'
import ServiceItem from '../../components/services/serviceItem/serviceItem'
import { useParams } from 'react-router-dom'


const Services = () => {
    const { t } = useTranslation('services', {keyPrefix: '',})
        
    const [applicationModal, setApplicationModal] = useState(false)
    const {section} = useParams()

    const scrollToSection = (id: string) => {
        const element = document.getElementById(`id-section-${id}`)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        const element = document.getElementById(`id-section-${section?.replace(/:/g, '')}`)
        console.log(section)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }

        if(!section){
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [])

    const data = [
        {
            title: t('popularServices'),
            id: 'popular-services',
            items:[
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image1.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image2.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image3.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image4.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image5.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image6.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image7.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image8.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image9.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image10.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image11.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/popularServices/image12.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                
            ]
        },
        {
            title: t('securityOfPremises'), 
            id: 'security-of-premises',
            items:[
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image1.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image2.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image3.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image4.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image5.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
                {
                    title: t('popularServicesItemTitle1'),
                    text: t('popularServicesItemText1'),
                    image: require('../../assets/images/services/securityOfPremises/image6.jpg'),
                    price: t('popularServicesItemPrice1'),
                    priceInfo: t('popularServicesItemPriceInfo1')
                },
            ]
        },
        {
            title:t('protectionOfObjects'),
            id: 'protection-of-objects',
            items: []
        },
        {
            title:t('remoteControl'),
            id: 'remote-control',
            items: []
        },
        {
            title:t('fireSafety'),
            id: 'fire-safety',
            items: []
        },
        {
            title:t('otherServices'),
            id: 'other-services',
            items: []
        }
    ]

    return (
        <Layout>
             <div className="services-wrapper">
                <div className="services-header">
                    {data.map((section, index) => (
                        <div
                            key={`services-header-title-${index}`}
                            className="services-header-title-container"
                            onClick={() => scrollToSection(section.id)}
                        >
                            <div className="services-header-title">{section.title}</div>
                        </div>
                    ))}
                </div>
                <div className="services-offers-list">
                    {data.map((section, index) => (
                        <div key={index} id={`id-section-${section.id}`} className="services-offer-list">
                            <div className="services-offer-title">{section.title}</div>
                            <div className="services-offer-items-container">
                                {section.items.map((offerItem, i) => (
                                    <ServiceItem
                                        title={offerItem.title} 
                                        text={offerItem.text} 
                                        image={offerItem.image} 
                                        price={offerItem.price} 
                                        priceInfo={offerItem.priceInfo}
                                        index={i}
                                        onClick={()=>setApplicationModal(!applicationModal)}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <FeedbackModal
                    opened={applicationModal}
                    setOpened={() => setApplicationModal(!applicationModal)}
                />
            </div>
        </Layout>
    )
}

export default Services