//styles
import './header.scss'
import { logo } from '../../../assets/icons'

//react
import { Link, useNavigate } from 'react-router-dom'

//components
import Burger from './burger/burger'
import LanguageSelector from './languageSelector/languageSelector'

//translation
import { useTranslation } from 'react-i18next'

const Header = () => {
    const { t } = useTranslation('general', {keyPrefix: 'header'})

    const navigate = useNavigate()

    return (
        <div className="header" id="header">
            <div className="container">
                {
                    window.screen.width > 768 ?
                        <div className="header-container">
                            <div className="header-contact">
                                <div className="header-contact-phone-numbers">
                                    <a href="">13144001284</a>
                                </div>
                                <LanguageSelector/>
                            </div>
                            <div className="header-content">
                                <div className="header-content-logo" onClick={() => {
                                    navigate('/')
                                }}>
                                    {logo}
                                </div>
                                <div className="header-content-nav">
                                    <Link to={'/services'}>{t("services")}</Link>                        
                                    <Link to={'/payment'}>{t("payment")}</Link>
                                    <Link to={'/contacts'}>{t("contactUs")}</Link>
                                </div>
                            </div>
                        </div>
                    : 
                    <div className="header-container">
                        <div className="header-content">
                            <div className="header-content-logo" onClick={() => {
                                    navigate('/')
                                }}>
                                {logo}
                            </div>
                            <Burger/>
                        </div>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default Header