//styles
import './adminPaginationButton.scss'

//react
import { FC } from 'react'

type adminPaginationButtonProps = {
    children: JSX.Element[] | JSX.Element 
    onClick: () => void
    active?: boolean
}

const AdminPaginationButton: FC<adminPaginationButtonProps> = ({ children, onClick, active=false }) => {
    return (
        <div className={`admin-pagination-button ${active ? 'active' : ''}`} onClick={onClick}>
            {children}
        </div>
    )
}

export default AdminPaginationButton