//styles
import './footer.scss'

//react
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation('general', {keyPrefix: 'footer',})
    return(
        <div className="footer-wrapper">
            <div className="footer-container">
                <div className="footer-logo">Security21</div>
                <div className="footer-links-wrapper">
                    <div className="footer-links-container">
                        <div className="footer-links-title">{t('company')}</div>
                        <Link to={'/services'}>{t('services')}</Link>
                        <Link to={'/payment'}>{t('payment')}</Link>
                        <Link to={'/contacts'}>{t('contactUs')}</Link>
                    </div>
                    <div className="footer-links-container">
                        <div className="footer-links-title">{t('phoneNumber')}</div>
                        <Link to={''}>000000000000</Link>
                        <p>{t('salesDepartament')}</p>
                        <p>{t('free')}</p>
                    </div>
                    <div className="footer-links-container">
                        <div className="footer-links-title">{t('address')}</div>
                        <p>{t('addressCity')}</p>
                        <p>{t('timings')}</p>
                        <p>{t('office')}</p>
                    </div>
                </div>
            </div>
            <div className="footer-info">
                <p>{t('copyright')}</p>
            </div>
        </div>
    )
}

export default Footer