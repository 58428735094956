//styles
import './modal.scss'

//reacy
import { FC, MouseEvent, ReactNode } from 'react'

type modalProps = {
    children: ReactNode
    opened: boolean
    setOpened: (value: boolean) => void
}

const Modal: FC<modalProps> = ({children, opened, setOpened}) => {

    const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
          setOpened(false)
        }
    }

    return(
        <div 
            className={`modal ${opened ? 'show' : ''}`}
            onClick={handleBackgroundClick} 
        >
            {children}
        </div>
    )
    
}

export default Modal